.card{
    width: 70%;
    aspect-ratio: 3/4;
    background: #fff;
    box-shadow: 2px 2px 4px #00000033;
    font-family:Poppins;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    border-top-left-radius: 0;
    transition: 800ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    transform-origin: 0% 0%;
    animation: zoomin 0.5s 1;
}
.card-paragraph{
    font-size: 0.9rem;
    margin: 0;
}
.card-image{
    flex: 1;
    aspect-ratio: 3/2;
    object-fit:cover;
}
.card-footer{
    height: fit-content;
    width:100%;
    height: 50px;
}

.card-link,.card-link:visited{
    text-decoration: none;
    color: #0C5CA7;
}
@keyframes zoomin {
    0%{
        transform: scale(0.2);
        opacity: 0;
    }
}
@media (max-width: 768px) {
    .card{
        width: 85%;
        padding: 10px;
    }
}
