.footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
    box-sizing: border-box;
    padding: 2%;
    gap:14%;
}
.link_group{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.link_group a{
    text-decoration: none;
    font-size: 16px;
    font-family: Poppins;
    color: #000;
    display: flex;
    align-items: center;
    gap:10px;
}
h3{
    margin: 0;
    font-family: Poppins;
    font-weight: 600;
}


@media (max-width: 768px) {
    .footer{
    flex-direction: column;
    align-items: center;
    padding-top: 5%;
    gap:50px;
    }
    .link_group{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}