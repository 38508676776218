.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: #fff;
    box-sizing: border-box;
    padding: 2%;
    border-radius: 10px;
    gap: 20px;
  }
  .login-container .button{
    width:300px;
    margin: 0;
  }
  h2{
    font-size: 30px;
  }
  .login-input {
    width: 300px;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 10px;
    position:relative;
    border: 1px solid #aaa;
  }
  
  .login-input input{
    position:absolute;
    border: none;
    padding: 8px;
    height:100%;
    width:100%;
    box-sizing:border-box;
    outline:none;

  }
  .login-input label{
    font-family: Poppins;
    position:absolute;
    pointer-events:none;
    width:max-content;
    margin:10px 15px;
    box-sizing:border-box;
    transition:300ms;
  }
  .login-input input:focus ~ label{
    background:#fff;
    font-size:13px;
    padding:0 5px;
    margin-left:10px;
    top:-20px;
  }
  .login-input input:not(:placeholder-shown) ~ label{
    background:#fff;
    font-size:13px;
    padding:0 5px;
    margin-left:10px;
    top:-20px;
  }

  .login-link{
    font-weight: 600;
    color: rgb(28, 92, 171);
    cursor: pointer;
  }
  .login-error{
    color: #c20000;
  }
  .backdrop{
    width:100vw;
    height:100vh;
    background: #0000005f;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
  @media (max-width: 768px) {
    .login-container {
      width: 90%;
    }
  
  }