.image_selection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.drop_container{
    width:70%;
    height: 300px;
    border: 2px dashed #000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
   }

.drop_container.active{
    background: #0000001f;

}

.draganddrop_text{
    font-size: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.example_container{
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-top: 1%;
    font-size: 20px;
}
.examples{
    padding-top: 1%;
    display: flex;
    justify-content: center;
    gap: 3%;
}
.example{
    width: 18%;
    object-fit: cover;
    aspect-ratio:6/7;
    cursor: pointer;
}
.preview-container{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.button-group{
    display: flex;
    gap: 20px;
}
.preview-image{
    width: 100%;
    height: 100%;
    object-fit:contain;
}

@media (max-width: 768px) {
    .drop_container{
        flex-direction: column;
        padding: 5%;
        margin-bottom: 10%;
    }
    .drop_container img{
        width: 30%;
    }
    .draganddrop_text{
        text-align: center;
        align-items: center;
    }
    .example_container{
        align-items: center;
    }
    .examples{
        margin-top: 10%;
        flex-wrap: wrap;
        justify-content: center;
        gap:20px;
    }
    .example{
        width: 40%;
    }

}