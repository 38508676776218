.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 2%;
    box-sizing: border-box;
    position: absolute;
    z-index:2;
}
.logo{
    width: 100px;
}
.nav_links{
    display: flex;
    align-items: center;
    gap:50px;
}

.link{
    text-decoration: none;
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    cursor: pointer;
}
.navbtn{
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
}
.avatar{
    height:40px;
    width: 40px;
    border-radius: 200px;
    cursor: pointer;
    font-size: 20px;
    font-family: Poppins;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccc;
    color: #fff;
}
.nav-dropdown{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.dropdown-content{
    display: none;
    position: absolute;
    top: 50px;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    /* display: flex; */
    flex-direction: column;
    gap: 10px;
    border: 1px solid #aaa;
    font-family: Poppins;
}
.dropdown-header{
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #aaa;
}
.dropdown-button{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}
.dropdown-button:hover{
    background: #00000027;
}
@media (max-width: 768px) {
    .navbtn{
        display: block;
    }
    .nav{
        position: relative;
        flex-direction: column;
    }
    .logo{
        position: absolute;
        left: 15px;
        top: 5px;
    }
    .nav_links{
        max-height: 0;
        overflow: hidden;
        flex-direction: column;
        gap:15px;
        margin-top:45px;
        transition: 200ms;
    }
    .nav_links.open{
        max-height:120px;
        transition:200ms;
    }
}