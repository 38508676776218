.main-section{
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.preview{
    height: 60%;
    padding: 15px;
    padding-top: 30px;
    background: #fff;
    box-shadow: 2px 2px 4px #00000033;
    display:flex;
    flex-direction: column;
    position: relative;
    font-size: 1rem;
    gap: 10px;
}
.preview .button{
    width: 100%;
}
.preview .button img{
    mix-blend-mode: darken;
}
.close-button{
    position: absolute;
    right:10px;
    top:10px;
    width: 15px;
    cursor: pointer;
}

.preview-image{
    flex: 1;
    object-fit: cover;
    aspect-ratio: 3/4;
    width: 100%;
}
.loading-icon{
    width:30px;
    height: 15px;
    margin: 0 10px;
    object-fit: cover;
}
.result-container{
    width: 40%;
    height: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    padding-bottom: 50px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.result-container::-webkit-scrollbar{
    display: none;
}

.message{
    background: #fff;
    box-shadow: 2px 2px 4px #00000033;
    border-radius:10px;
    border-top-left-radius: 0;
    padding: 10px;
    font-family: Poppins;
    box-sizing: border-box;
    max-width: 550px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: zoomin 0.5s;
    animation-fill-mode: backwards;
    transform-origin: 0% 0%;
}
.message:nth-child(1){animation-delay:1s;}
.message:nth-child(2){animation-delay:2s;}
.message:nth-child(3){animation-delay:3s;}
.message:nth-child(4){animation-delay:4s;}
.message:nth-child(5){animation-delay:5s;}
.message p{
    margin: 0;
}
.color-palette{
    display: flex;
    gap: 20px;
}
.color{
    width: 30px;
    height: 30px;
    border-radius: 20px;
}
@media (max-width: 768px) {
    .main-section{
        align-items: center;
    }
    .result-container{
        width: 90%;
    }
    .preview{
        width: 60%;
        font-size: 0.9rem;
    }
    .main-section{
        flex-direction: column;
        gap:50px;
    }
}
